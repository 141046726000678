<template>
  <div  :style="{height:bodyHeight+'px'}">
    <div class="mainDiv" >
      <div class="mapDiv"></div>
      <div class="loginMainDiv">
        <div class="loginDiv">
          <img style="height: 61px;" src="../assets/images/login/pic_logo.png" />
          <div style="display: flex;justify-content: center;align-items: center;">
            <div style="width: 368px;margin-top: 48px;">
              <a-form :form="form"
                      @submit="handleSubmit">
                <a-form-item style="text-align: left">
                  <a-input placeholder="请输入用户名" autocomplete="false"
                           v-decorator="[
                                          'account',
                                          {rules: [{ required: true, message: '请输入用户名' }], validateTrigger: 'change'}
                                        ]"
                  />
                </a-form-item>
                <a-form-item style="text-align: left">
                  <a-input type="password"  autocomplete="false" placeholder="请输入密码"
                           v-decorator="[
                                          'password',
                                          {rules: [{ required: true, message: '请输入密码' }], validateTrigger: 'blur'}
                                        ]"
                  />
                </a-form-item>
                <a-form-item>
                  <a-button
                      size="large"
                      type="primary"
                      html-type="submit"
                      class="login-button"
                      :loading="state.loginBtn"
                      :disabled="state.loginBtn"
                  >
                    登录
                  </a-button>
                </a-form-item>
              </a-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getNoExistNavigatorBodyHeignt } from '../core/bodyHeigntUtil'
import notification from 'ant-design-vue/es/notification'
import { mapActions } from 'vuex'
import Vue from 'vue'
import {validatePasswordStrength } from '../utils/regexUtil'
import {getRSApass} from '../utils/util'
export default {
name: "Login",
  data(){
    return{
      bodyHeight:"",
      form: this.$form.createForm(this),
      state: {
        time: 60,
        loginBtn: false
      }
    }
  },
  methods:{
    ...mapActions(['Login', 'Logout']),
    weakPasswordCheck (password) {
      let state = 0
      if (password.length<8) {
        state = 1
      }else {
          state = validatePasswordStrength(password) ? 1 : 0
      }
      return state
    },
    handleSubmit(e) {
      e.preventDefault()
      const {
        form: { validateFields },
        state,
        Login
      } = this
      state.loginBtn = true
      let _then = this
      validateFields(['account', 'password'], { force: true }, (err, values) => {
        if (!err) {
          const loginParams = { ...values }
          delete loginParams.account
          loginParams['account'] = getRSApass(values.account)
          loginParams['password'] = getRSApass(values.password)
          Login(loginParams)
              .then(() => {
                Vue.ls.set('stage', this.weakPasswordCheck(values.password))
                _then.loginSuccess()
              })
              .catch((err) => {
                console.log(err)
                notification.error({
                  message: '登录失败',
                  description: '用户名或密码错误'
                })
              })
              .finally(() => {
                state.loginBtn = false
              })
        } else {
          setTimeout(() => {
            state.loginBtn = false
          }, 600)
        }
      })
    },
    loginSuccess () {
        this.$router.push("/monitorIndex");
    },
    requestFailed (err) {
      console.log(err)
    },
  },

  beforeMount:function () {
    this.bodyHeight = getNoExistNavigatorBodyHeignt();
  },
  mounted:function () {
    let that = this;
    that.$nextTick(function () {
      window.onresize = () => {
        return (() => {
          that.bodyHeight = getNoExistNavigatorBodyHeignt();
        })()
      };
    });
  }
}
</script>

<style scoped>
  .mainDiv {
    height: 100%;
    background-image: url('../assets/images/login/bg.png');
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .mapDiv{
    width: 800px;
    height: 500px;
    background-image: url('../assets/images/login/gsmap.png');
  }
  .loginMainDiv{
    margin-left: 120px;
    width: 518px;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;

  }
  .loginDiv{
    height: 305px;
    width: 100%;
    text-align: center;
  }
  .ant-input{
    height: 44px;
    background: rgba(0, 50, 168, 0.1);
    border: 1px solid #6679A5;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 24px;
    color: #ffffff !important;
    opacity: 1;
    border-radius: 4px;
  }
  .ant-input:focus, .ant-input:hover {
    border-color: #2499E8;
    border-right-width: 1px!important;
  }
  .pwdInput{
    margin-top: 32px;
  }
  .has-error .ant-input, .has-error .ant-input:hover {
    background: rgba(0, 50, 168, 0.1);
    border-color: #2499E8;
  }
  button.login-button {
    padding: 0 15px;
    font-size: 16px;
    height: 40px;
    width: 100%;
  }

</style>
